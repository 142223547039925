import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import { PageProps, PagePropTypes } from "./index";

import Layout from "../components/Layout";
import Seo from "../components/Seo";

const NotFoundPage: React.FC<PageProps> = ({ pageContext }) => {
  const intl = useIntl();
  const {
    intl: { language, languages },
  } = pageContext;
  const title = intl.formatMessage({ id: "pageNotFound" });
  return (
    <Layout title={title} description={intl.formatMessage({ id: "pageDoesNotExist" })}>
      <Seo title={title} language={language} languages={languages} />
    </Layout>
  );
};

NotFoundPage.propTypes = PagePropTypes;

export default NotFoundPage;
